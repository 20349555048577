.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  