.search-bar {
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }
  
  .search-bar input {
    width: 300px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .search-bar input:focus {
    outline: none;
    border-color: #007bff;
  }
  