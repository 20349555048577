.product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    width: 200px;
    text-align: left;
  }
  
  .product-card img {
    /* width: 100%; */
    /* height: auto; */
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
    max-height: 200px;

  }
  
  .product-card h2 {
    font-size: 1.2em;
    margin: 0 0 10px 0;
  }
  
  .product-card p {
    font-size: 0.9em;
    margin: 0 0 10px 0;
  }
  
  .product-card a {
    color: #007bff;
    text-decoration: none;
  }
  
  .product-card a:hover {
    text-decoration: underline;
  }
  
  .social-links {
    display: flex;
    justify-content: start;
    margin-top: 10px;
  }
  
  .social-links a {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
  
  .social-links img {
    width: 100%;
    height: 100%;
  }
  