.App {
    text-align: center;
  }
  
  header {
    background-color: #282c34;
    padding: 20px;
    color: white;
  }
  
  .product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    width: 200px;
    text-align: left;
  }
  
  .product-card img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .product-card h2 {
    font-size: 1.2em;
    margin: 0 0 10px 0;
  }
  
  .product-card p {
    font-size: 0.9em;
    margin: 0 0 10px 0;
  }
  
  .product-card a {
    color: #007bff;
    text-decoration: none;
  }
  
  .product-card a:hover {
    text-decoration: underline;
  }
  